<template>
  <!-- 短信模板 -->
  <div class="SMS-template-configuration-page">
    <div class="add-btn">
      <el-button
        v-if="listFind('短信业务代码')"
        type="primary"
        size="small"
        @click="openDialogFun('短信业务代码')"
        >短信业务代码</el-button
      >
      <el-button
        v-if="listFind('添加')"
        type="primary"
        size="small"
        @click="openDialogFun('新增')"
        >添加</el-button
      >
    </div>
    <div class="table-box">
      <Table
        :table-data="tableData"
        :title-name="titleName"
        operation
        col-class-type
        operation-width="200"
      >
        <template slot-scope="scope">
          <div>
            <template>
              <el-button
                v-if="listFind('编辑')"
                type="text"
                size="small"
                sort="primary"
                @click="openDialogFun('编辑', scope.scopeRow)"
                >编辑</el-button
              >
              <el-button
                v-if="listFind('配置')"
                type="text"
                size="small"
                sort="primary"
                @click="openDialogFun('配置', scope.scopeRow)"
                >配置</el-button
              >
            </template>
          </div>
        </template>
      </Table>
    </div>
    <div class="dialog-box">
      <el-dialog
        v-if="dialogVisible"
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="30%"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="渠道：" prop="channelNo">
            <el-select
              v-model="channelNo"
              :disabled="dialogTitle === '编辑'"
              value-key="channelNo"
              clearable
              placeholder="请选择渠道"
              @change="changeSelect"
            >
              <el-option
                v-for="(item, index) in channelList"
                :key="index"
                :label="item.channelName"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="accessKey：" prop="accessKey">
            <el-input
              v-model.trim="ruleForm.accessKey"
              placeholder="请输入accessKey"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="secretKey：" prop="secretKey">
            <el-input
              v-model.trim="ruleForm.secretKey"
              placeholder="请输入secretKey"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="关联签名：" prop="smsSign">
            <el-input
              v-model.trim="ruleForm.smsSign"
              placeholder="请输入关联签名"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmFun">确 认</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  queryChannelListAPI,
  querySmsLicensePage,
  querySmsLicenseInfo, //详情
  updateSmsLicense, //更新
  saveSmsLicense,
} from "@/api/lib/api.js";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      dialogTitle: "",
      channelNo: {},
      ruleForm: {
        channelNo: "",
        channelName: "",
        accessKey: "",
        secretKey: "",
        smsSign: "",
      },
      rules: {
        channelNo: [
          { required: true, message: "请选择渠道", trigger: "change" },
        ],
        smsSign: [
          { required: true, message: "请输入关联签名", trigger: "blur" },
        ],
        accessKey: [
          { required: true, message: "请输入accessKey", trigger: "blur" },
        ],
        secretKey: [
          { required: true, message: "请输入secretKey", trigger: "blur" },
        ],
      },
      channelList: [], //渠道数据
      tableData: [],
      titleName: [
        {
          title: "渠道",
          props: "channelName",
        },
        {
          title: "accessKey",
          props: "accessKey",
        },
        {
          title: "secretKey",
          props: "secretKey",
        },
        {
          title: "关联签名",
          props: "smsSign",
        },
      ],
      detail: null,
    };
  },
  filters: {},
  computed: {},
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.dialogTitle = "";
        this.ruleForm = this.$options.data().ruleForm;
        this.channelNo = {};
        this.channelList = [];
      }
    },
  },
  created() {
    this.getTableData();
  },
  mounted() {},
  methods: {
    /**
     * @description 请求列表数据
     */
    getTableData() {
      querySmsLicensePage().then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data?.list || [];
        }
      });
    },
    /**
     * @description 打开弹窗
     */
    openDialogFun(title, row) {
      this.dialogTitle = title;
      if (title === "配置") {
        this.$router.push({
          path: "/projectTravel/SMSTemplateConfiguration/SMSTemplateDetail",
          query: { channelNo: row.channelNo },
        });
        return;
      } else if (title === "短信业务代码") {
        this.$router.push(
          "/projectTravel/SMSTemplateConfiguration/SMSServiceCode"
        );
        return;
      } else if (title === "编辑") {
        this.detail = this.deepClone(row);
        this.channelNo = {
          channelNo: row.channelNo,
          channelName: row.channelName,
        };
        this.ruleForm = JSON.parse(JSON.stringify(row));
        // querySmsLicenseInfo({channelNo:row.channelNo}).then(res=>{
        //   if(res.code === "SUCCESS"){
        //     this.ruleForm = res.data;
        //   }
        // })
      }
      this.getQueryChannelList();
      this.dialogVisible = true;
    },
    /**
     * @description 确认回调
     */
    confirmFun() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.dialogTitle === "编辑") {
            if (this.ruleForm.secretKey == this.detail.secretKey) {
              this.ruleForm.secretKey = null;
            }
            if (this.ruleForm.accessKey == this.detail.accessKey) {
              this.ruleForm.accessKey = null;
            }
            updateSmsLicense(this.ruleForm).then((res) => {
              if (res.code == "SUCCESS") {
                this.$message.success(res.msg);
                this.dialogVisible = false;
                this.getTableData();
              }
            });
          } else {
            saveSmsLicense(this.ruleForm).then((res) => {
              if (res.code == "SUCCESS") {
                this.$message.success(res.msg);
                this.dialogVisible = false;
                this.getTableData();
              }
            });
          }
        }
      });
    },
    /**
     * @description 获取渠道数据
     */
    getQueryChannelList() {
      queryChannelListAPI({ status: 0 }).then((res) => {
        if (res.code == "SUCCESS") {
          this.channelList = res.data;
        }
      });
    },
    //选择渠道
    changeSelect(val) {
      this.ruleForm.channelName = val.channelName;
      this.ruleForm.channelNo = val.channelNo;
    },
  },
};
</script>

<style lang="scss" scoped>
.SMS-template-configuration-page {
  .table-box {
    margin-top: 20px;
  }
  .dialog-box {
    .demo-ruleForm {
      .el-select {
        width: 100%;
      }
    }
  }
}
</style>
